import React, { useContext, useEffect, useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Checkbox, Dialog, DialogContent, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import axios from 'axios';
import { AdminContext } from '../../context/AdminContext';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import AdvertisementPhotoDialog from './AdvertisementPhotoDialog';
import { FormattedMessage, useIntl } from 'react-intl';

function AdvertisementEditDialog({ advertisement, dialogOpen, handleClose, handleFormSubmit }) {

    const { token, admin } = useContext(AdminContext);
    const { formatMessage } = useIntl();
    const [advertisementCategories, setAdvertisementCategories] = useState([]);
    const [advertisementPhotoDialogState, setAdvertisementPhotoDialogState] = useState({ open: false, photo_link: null });

    const fetchAdvertisementCategories = async (token, language) => {
        axios.patch(
            `${process.env.REACT_APP_API_URL}webAdmin/getCategories`,
            {
                orderBy: "name",
                orderType: "ASC",
                filter: {
                    id: "for_advertisements",
                    value: 1,
                    type: "select",
                },
                language: language
            },
            { headers: { token: token } }
        )
            .catch((err) => {
                setAdvertisementCategories([]);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    for (let i = 0; i < response.data.categories.length; i++) {
                        if (advertisement.categories.findIndex((cat) => cat.id === response.data.categories[i].id) > -1) {
                            response.data.categories[i] = advertisement.categories.find((cat) => cat.id === response.data.categories[i].id);
                        }
                    }
                    setAdvertisementCategories(response.data.categories);
                }
            });
    }

    useEffect(() => {
        fetchAdvertisementCategories(token, admin.language);
    }, [token, admin]);

    var initialValues = {
        is_active: advertisement?.is_truly_active && advertisement?.is_truly_active === 1 ? true : false,
        expiration_date: advertisement?.expiration_date ? dayjs(advertisement?.expiration_date) : null,
        advertisement_contents: advertisement?.advertisement_contents ? [...(advertisement?.advertisement_contents)] : [],
        categories: advertisement?.categories ? [...(advertisement?.categories)] : [],
        is_featured: advertisement?.is_truly_featured && advertisement?.is_truly_featured === 1 ? true : false,
        featured_end_date: advertisement?.featured_end_date ? dayjs(advertisement?.featured_end_date) : null,
        title: advertisement?.title ? advertisement?.title : "",
        description: advertisement?.description ? advertisement?.description : "",
    };

    var validationObject = {
        is_active: yup.boolean().required(<FormattedMessage id="required" />),
        expiration_date: yup.date().required(<FormattedMessage id="required" />).when("is_active", {
            is: (is_active) => is_active,
            then: () => yup.date().min(dayjs().add(1, "day"), <FormattedMessage id="invalid_date" />)
        }),
        advertisement_contents: yup.array().of(yup.object().shape({
            content_link: yup.string().required(<FormattedMessage id="required" />),
            content_path: yup.string().required(<FormattedMessage id="required" />),
            is_photo: yup.boolean().required(<FormattedMessage id="required" />)
        })).required(<FormattedMessage id="required" />),
        categories: yup.array().of(yup.object().shape({
            id: yup.number().required(<FormattedMessage id="required" />),
            name: yup.string().required(<FormattedMessage id="required" />)
        })).required(<FormattedMessage id="required" />),
        is_featured: yup.boolean().required(<FormattedMessage id="required" />),
        featured_end_date: yup.date().required(<FormattedMessage id="required" />).when("is_featured", {
            is: (is_featured) => is_featured,
            then: () => yup.date().min(dayjs().add(1, "day"), <FormattedMessage id="invalid_date" />)
        }),
        title: yup.string().required(<FormattedMessage id="required" />),
        description: yup.string().nullable(),
    }

    const advertisementSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }}
            open={dialogOpen}
            fullWidth
            maxWidth="lg"
            onClose={(e, reason) => {
                if (reason && reason === "backdropClick") {
                    return;
                }
                handleClose();
            }}>
            {advertisementPhotoDialogState.open && advertisementPhotoDialogState.photo_link &&
                <AdvertisementPhotoDialog
                    advertisementPhotoDialogState={advertisementPhotoDialogState}
                    setAdvertisementPhotoDialogState={setAdvertisementPhotoDialogState}
                />
            }
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    <FormattedMessage id={"edit_advertisement"} />({advertisement.id}) - <FormattedMessage id={"advertisement_no"} />: {advertisement.advertisement_no}
                </Typography>

                <Button
                    variant="standard"
                    color="error"
                    sx={{
                        borderRadius: "100%",
                        backgroundColor: "white",
                        maxWidth: "40px",
                        maxHeight: "40px",
                        minWidth: "40px",
                        minHeight: "40px",
                        position: "absolute",
                        top: "10px",
                        right: "10px"
                    }}
                    onClick={handleClose}>
                    <p style={{ color: "red", fontSize: "20px" }}>✘</p>
                </Button>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={advertisementSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue, resetForm }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="20px" gridTemplateColumns="repeat(3, minmax(0, 1fr))" mx="75px" my="25px">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={values.is_active}
                                                size="small"
                                            />}
                                        variant="filled"
                                        label={formatMessage({ id: "active_advertisement" })}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"is_active"}
                                        value={values.is_active}
                                        sx={{ gridColumn: "span 1" }} />
                                    {values.is_active &&
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                fullWidth
                                                variant="filled"
                                                label={formatMessage({ id: "expiration_date_with_explanation" })}
                                                onBlur={handleBlur}
                                                onChange={(date) => setFieldValue("expiration_date", date)}
                                                name={"expiration_date"}
                                                value={values.expiration_date}
                                                error={!!touched.expiration_date && !!errors.expiration_date}
                                                helperText={touched.expiration_date && errors.expiration_date}
                                                sx={{ gridColumn: "span 1" }}
                                            />
                                        </LocalizationProvider>
                                    }
                                    <Box display="grid" columnGap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))" gridColumn="span 3" my={"30px"}>
                                        {
                                            values.advertisement_contents.map((content, index) =>
                                                <Box key={content.id} gridColumn={"span 1"} my={0}>
                                                    {!(values.advertisement_contents.length === 1 && content.content_path.includes("default")) &&
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            sx={{
                                                                position: "relative",
                                                                top: "10px",
                                                                right: "10px",
                                                                zIndex: 1
                                                            }}
                                                            onClick={() => {
                                                                let newContents = values.advertisement_contents;
                                                                newContents.splice(index, 1);
                                                                setFieldValue("advertisement_contents", newContents);
                                                            }}>
                                                            <FormattedMessage id="delete" />
                                                        </Button>
                                                    }
                                                    <Box
                                                        component={content.is_photo ? "img" : "video"}
                                                        controls
                                                        borderRadius="12px"
                                                        src={content.content_link}
                                                        alt={`advertisement-sm-${index}`}
                                                        width={255}
                                                        height={153}
                                                        margin={"auto"}
                                                        {...(content.is_photo ? {
                                                            onClick: () => {
                                                                setAdvertisementPhotoDialogState({ open: true, photo_link: content.content_link });
                                                            },
                                                            style: { cursor: "pointer", objectFit: "cover" }
                                                        } : {
                                                            style: { objectFit: "contain" }
                                                        })}
                                                    />
                                                </Box>
                                            )
                                        }
                                        {values.advertisement_contents.length === 0 &&
                                            <Box gridColumn={"span 1"} my={0}>
                                                <Box
                                                    component="img"
                                                    borderRadius="12px"
                                                    src={process.env.REACT_APP_WEB_URL + "images/owajoo/advertisements/default.png"}
                                                    alt={`advertisement-sm-${0}`}
                                                    width={255}
                                                    height={153}
                                                    margin={"auto"}
                                                    onClick={() => {
                                                        setAdvertisementPhotoDialogState({ open: true, photo_link: process.env.REACT_APP_WEB_URL + "images/owajoo/advertisements/default.png" });
                                                    }}
                                                    style={{ cursor: "pointer", objectFit: "cover" }}

                                                />
                                            </Box>
                                        }
                                    </Box>

                                    {advertisementCategories?.length > 0 &&
                                        <>
                                            <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2" }}>
                                                <InputLabel id="categories"><FormattedMessage id={"categories"} /></InputLabel>
                                                <Select
                                                    labelId="categories"
                                                    id="categories"
                                                    multiple
                                                    value={values.categories}
                                                    onChange={(e) => {
                                                        if (e.target.value.length > 2 || e.target.value.length === 0) {
                                                            return;
                                                        }
                                                        handleChange(e);
                                                    }}
                                                    name={"categories"}
                                                    onBlur={handleBlur}
                                                    error={!!touched.categories && !!errors.categories}
                                                    renderValue={(selected) => selected.map((category) => category.name).join(', ')}
                                                >
                                                    {advertisementCategories.map((category) => (
                                                        <MenuItem key={category.id} value={category}>
                                                            {
                                                                /*
                                                                <Checkbox
                                                                    checked={values.subcategories.findIndex((subct) => subct.id === subcategory.id) > -1} />
                                                                {subcategory.name}
                                                                */
                                                            }
                                                            <Checkbox checked={values.categories.findIndex((cat) => cat.id === category.id) > -1} />
                                                            {category.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <Box sx={{ gridColumn: "span 1" }} />
                                        </>
                                    }

                                    {values.is_active &&
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.is_featured}
                                                    size="small"
                                                />}
                                            variant="filled"
                                            label={formatMessage({ id: "featured_advertisement" })}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"is_featured"}
                                            value={values.is_featured}
                                            sx={{ gridColumn: "span 1" }} />

                                    }

                                    {values.is_active && values.is_featured &&
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DateTimePicker
                                                fullWidth
                                                variant="filled"
                                                label={formatMessage({ id: "featured_advertisement_expiration_date_with_explanation" })}
                                                onBlur={handleBlur}
                                                onChange={(date) => setFieldValue("featured_end_date", date)}
                                                name={"featured_end_date"}
                                                value={values.featured_end_date}
                                                error={!!touched.featured_end_date && !!errors.featured_end_date}
                                                helperText={touched.featured_end_date && errors.featured_end_date}
                                                sx={{ gridColumn: "span 1" }}
                                            />
                                        </LocalizationProvider>
                                    }


                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "table.columns.title" }).charAt(0).toUpperCase() + formatMessage({ id: "table.columns.title" }).slice(1).toLocaleLowerCase()}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="title"
                                        value={values.title}
                                        error={!!touched.title && !!errors.title}
                                        helperText={touched.title && errors.title}
                                        sx={{ gridColumn: "span 3", }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label={formatMessage({ id: "table.columns.description" }).charAt(0).toUpperCase() + formatMessage({ id: "table.columns.description" }).slice(1).toLocaleLowerCase()}
                                        multiline
                                        minRows={3}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name="description"
                                        value={values.description}
                                        error={!!touched.description && !!errors.description}
                                        helperText={touched.description && errors.description}
                                        sx={{ gridColumn: "span 3", marginTop: "20px" }}
                                    />

                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px" gap={"10px"}>
                                    <Button type="button" color="warning" variant="contained" onClick={() => {
                                        resetForm();
                                    }}>
                                        <FormattedMessage id="reset" />
                                    </Button>
                                    <Button type="button" color="error" variant="contained" onClick={handleClose}>
                                        <FormattedMessage id="cancel" />

                                    </Button>
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <FormattedMessage id={"save"} />
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default AdvertisementEditDialog